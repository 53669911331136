// // Use axios as default http client
const axios = require('axios');

module.exports = {
  login: (payload) => {
    module.exports.storeJwt(payload);
    // console.log("jwt stored")
  },
  logout: () => {
    localStorage.removeItem("newsfeed_auth_token");
    window.location.href = process.env.VUE_APP_CAS_URL_LOGOUT;
  },
  storeJwt: (payload) => {
    // Store it also to the local storage (to maintain a valid session even if page reloaded)
    localStorage.setItem('newsfeed_auth_token', payload)
  },
  validateJwt: async () => {
    return new Promise((resolve, reject) => {
      // Load the jwt from memory if present
      let jwt = localStorage.getItem("newsfeed_auth_token");
      if (!(jwt === null)) {
        // Ask to backend if current found jwt is valid, if it is then use it else ask for a new jwt
        // Prepare the message
        const headers = {
          Authorization: "jwt " + jwt,
        };

        const body = {};

        //console.log(Vue);
        // console.log(process.env.VUE_APP_NEWSFEED_API + "/jwtVerify")
        axios.post(process.env.VUE_APP_NEWSFEED_API + "/jwtVerify", body, {
          headers,
        })
          .then((response) => {
            //console.log(response);
            if (response.status === 200) {
              if (response.data.status === "valid") {
                // console.log("-> Valid token found -> autologin");
                // Valid token found, we reload the jwt user info passing from the common onValidate method
                module.exports.login(jwt);
                resolve("valid");
              }
            } else if (response.status === 404) {
              // console.log("The service is down. Please try again later...");
              //this.serviceDown = true;
              reject("servicedown");
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log(err.response.data);
            } else {
              console.log(err);
            }
            module.exports.logout();
            // console.log("JWT verification error...redirectin to CAS for new authentication!");
            reject("notvalid");
          });
      } else {
        reject("nullJWT")
      }
    })
  },
}

// // Used to decode the auth jwt received
// // import jwt_decode from "jwt-decode";

// module.exports = {
//    // Action used to login and out (do we have a valid jwt?)
//   login: (payload) => {
//     // console.log('Logged in correctly!');
//     this.storeJwt(payload)

//     // Extract from jwt initials and user role
//     // Parse jwt content and fulfill some infoes
//     // let decoded = jwt_decode(payload.jwt);
//     // let username = decoded.username;


//   },
//   logout: () => {
//     // console.log('Logging out!');
//     // Delete the auth token stored
//     localStorage.removeItem("newsfeed_auth_token");
//     // console.log('jwt removed from local storage!');
//     // Logout cas VUE_APP_CAS_URL_LOGOUT
//     window.location.href = process.env.VUE_APP_CAS_URL_LOGOUT;

//   },
//   // Action to store the jwt obtained by newsfeed backend for later http request use
//   storeJwt: (payload) => {
//     // Store it also to the local storage (to maintain a valid session even if page reloaded)
//     localStorage.setItem('newsfeed_auth_token', payload.jwt)
//   },
//   // Action to store the jwt obtained by newsfeed backend for later http request use
//   // Action to store the jwt obtained by newsfeed backend for later http request use
//   validateJwt: () => {
//     return new Promise((resolve, reject) => {
//       // Load the jwt from memory if present
//       let jwt = localStorage.getItem("newsfeed_auth_token");
//       if (!(jwt === null)) {
//         // Ask to backend if current found jwt is valid, if it is then use it else ask for a new jwt
//         // Prepare the message
//         const headers = {
//           Authorization: "jwt " + jwt,
//         };

//         const body = {};

//         //console.log(Vue);
//         let mylocalstore = this;
//         axios.post(process.env.VUE_APP_NEWSFEED_API + "/jwtVerify", body, {
//           headers,
//         })
//           .then((response) => {
//             //console.log(response);
//             if (response.status === 200) {
//               if (response.data.status === "valid") {
//                 // console.log("-> Valid token found -> autologin");
//                 // Valid token found, we reload the jwt user info passing from the common onValidate method
//                 mylocalstore.login(jwt);
//                 resolve("valid");
//               }
//             } else if (response.status === 404) {
//               // console.log("The service is down. Please try again later...");
//               //this.serviceDown = true;
//               reject("servicedown");
//             }
//           })
//           .catch((err) => {
//             if (err.response) {
//               console.log(err.response.data);
//             } else {
//               console.log(err);
//             }
//             mylocalstore.logout();
//             // console.log("JWT verification error...redirectin to CAS for new authentication!");
//             reject("notvalid");
//           });
//       }
//     })
//   },
// }