<template>
  <v-app>
    <v-container>
      <v-snackbar
        v-model="permSnackbar"
        :color="snackbarColor"
        :timeout="timeout"
      >
        {{ errorText }}
        <template v-slot:action="{ attrs }">
          <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-row>
        <v-col cols="3">
          <div class="d-flex align-center">
            <v-img
              class="ma-1"
              src="@/assets/unimi_logo.png"
              max-width="250"
              max-heigth="300"
            />
          </div>
        </v-col>
        <v-col cols="6"
          ><h2>
            Comunicazione Dipartimento di Informatica/Ufficio stampa di Ateneo
          </h2>
        </v-col>

        <v-spacer></v-spacer>
        <v-col cols="1">
          <v-btn @click="logout()" target="_blank" light color="primary">
            <span>Logout</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>{{ username }}</v-col>
      </v-row>
      <v-main v-if="loading">
        <v-overlay :value="overlay"
          ><v-progress-circular
            indeterminate
            color="primary"
            :size="70"
            :width="7"
          ></v-progress-circular
        ></v-overlay>
      </v-main>
      <v-main v-else-if="jwtPayload.role === 'admin'">
        <br />
        <br />
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="8">
            Questa pagina rappresenta lo strumento per fornire con continuità
            notizie all’Ufficio Stampa di Ateneo. Tale Ufficio seleziona poi le
            notizie più interessanti e le approfondisce sul sito di ateneo e/o
            con articoli divulgativi sulla stampa - ovviamente a valle di
            interviste fatte dai giornalisti delle varie testate ai referenti
            della specifica notizia. Le notizie da inoltrare all’Ufficio Stampa
            di Ateneo includono:
            <ul>
              <li>
                progetti finanziati (inserimento a cura della Segreteria
                Dipartimentale)
              </li>
              <li>
                attività di terza missione (inserimento a cura del gruppo Terza
                Missione)
              </li>
              <li>
                altre notizie importanti, ad esempio: pubblicazioni scientifiche
                e/o risultati di ricerca particolarmente rilevanti, premi e
                riconoscimenti, ecc. (le informazioni devono essere fornite
                dagli individui direttamente coinvolti)
              </li>
            </ul>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="8">
            <v-row class="field mb-2">
              <v-col
                cols="2"
                class="text-right font-weight-bold"
                align-self="center"
              >
                Tipologia notizia:<br />(obbligatoria)
              </v-col>
              <v-col cols="5" align-self="start">
                <v-select
                  :items="tipologie"
                  label="Tipologia"
                  v-model="tipologia"
                  item-text="longText"
                  item-value="shortText"
                ></v-select>
              </v-col>
              <v-col cols="4"> </v-col>
            </v-row>
            <v-row class="field mb-2">
              <v-col
                cols="2"
                class="text-right font-weight-bold"
                align-self="start"
              >
                Titolo notizia:<br />(obbligatorio)
              </v-col>
              <v-col cols="5" align-self="start">
                <v-text-field label="Titolo" v-model="titolo"></v-text-field>
              </v-col>
              <v-col cols="4" align-self="start">
                indicare in maniera sintetica il nome dell'attività di cui si
                fornisce comunicazione. Es: nome e acronimo del progetto, nome
                dell'evento o attività di Terza Missione, nome del premio o
                riconoscimento, titolo della pubblicazione scientifica.
              </v-col>
            </v-row>
            <v-row class="field mb-2">
              <v-col
                cols="2"
                class="text-right font-weight-bold"
                align-self="start"
              >
                Descrizione:<br />(obbligatoria)</v-col
              >
              <v-col cols="5" align-self="start">
                <v-textarea
                  label="Descrizione"
                  v-model="descrizione"
                  auto-grow
                  rows="10"
                ></v-textarea>
              </v-col>
              <v-col cols="4" align-self="start">
                descrivere in maniera sintetica l’evento o l’attività di cui si
                fornisce comunicazione e fornire i nomi e indirizzi email delle
                persone coinvolte da contattare per eventuali approfondimenti.
                <ul>
                  <li>
                    Per progetti finanziati, riportare la descrizione presente
                    nel verbale del Consiglio di Dipartimento, e il referente
                    del progetto.
                  </li>
                  <li>
                    Per attività di Terza Missione o altre notizie, riportare
                    sinteticamente una descrizione della attività, comprensiva
                    dei dettagli salienti (es: data, luogo, soggetti coinvolti,
                    ecc.), e del nome/i e indirizzi email della persona/e
                    coinvolta/e.
                  </li>
                </ul>
              </v-col>
            </v-row>
            <v-row class="field mb-2">
              <v-col>
                <v-row>
                  <v-col cols="2" class="text-right" align-self="start">
                    Allegati:<br />(facoltativi)</v-col
                  >
                  <v-col cols="5" align-self="start">
                    <v-file-input
                      label="Allega"
                      v-model="currentFiles"
                      multiple
                      @change="inputChanged()"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="4">
                    Allegare eventuale/i documento/i (pdf, doc, txt, o immagine)
                    con ulteriori dettagli/descrizione dettagliata della notizia
                  </v-col>
                </v-row>
                <v-row v-for="(file, index) of files" :key="index">
                  <v-spacer></v-spacer>
                  <v-col cols="6">
                    {{ file.name }}
                  </v-col>
                  <v-col cols="1">
                    <v-btn icon @click="remove(index)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row v-if="files.length > 0">
                  <v-spacer></v-spacer>
                  <v-col
                    cols="4"
                    :class="totalsize > maxsize ? 'red--text' : ''"
                  >
                    Dimensione totale: {{ bytesToMega(totalsize) }}
                    <span v-if="totalsize > maxsize">
                      (max {{ (maxsize / 1024 / 1024).toFixed() }} MB)</span
                    >
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mb-5">
              <v-spacer></v-spacer>
              <v-col cols="1" class="text-right">
                <v-btn
                  light
                  color="primary"
                  :disabled="invalidInput"
                  @click="submitData()"
                  >Invia</v-btn
                >
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-main>
      <v-main v-else>
        <v-spacer></v-spacer>
        <v-col cols="10">
          <br />
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="6"
              >Questo servizio è riservato agli afferenti al Dipartimento di
              Informatica "Giovanni degli Antoni"</v-col
            >
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
      </v-main>
    </v-container>
  </v-app>
</template>

<script>
const localstore = require("./localstore");
const querystring = require("querystring");
const URL = require("url");
import jwt_decode from "jwt-decode";

export default {
  name: "App",

  data: () => ({
    tipologie: [
      {
        shortText: "Progetto finanziato",
        longText:
          "Progetto finanziato (a cura della Segreteria Dipartimentale)",
      },
      {
        shortText: "Attività di terza missione",
        longText:
          "Attività di terza missione (a cura del gruppo Terza Missione)",
      },
      {
        shortText: "Altra notizia",
        longText: "Altra notizia (a discrezione degli individui coinvolti)",
      },
    ],
    tipologia: "",
    titolo: "",
    descrizione: "",
    jwtPayload: {},
    loading: false,
    username: null,
    files: [],
    currentFiles: [],
    permSnackbar: false,
    snackbarColor: "success",
    errorText: "",
    timeout: -1,
    overlay: true,
    totalsize: 0,
    maxsize: 10 * 1024 * 1024,
  }),
  watch: {
    files() {
      this.totalsize = 0;
      for (let file of this.files) {
        this.totalsize += file.size;
      }
    },
  },
  methods: {
    bytesToMega(num) {
      return (num / 1024 / 1024).toFixed(1) + " MB";
    },
    submitData() {
      this.loading = true;
      let formData = new FormData();
      let fileIndex = 0;
      for (let file of this.files) {
        formData.append("file" + fileIndex, file);
        fileIndex += 1;
      }
      formData.append("tipologia", this.tipologia);
      formData.append("titolo", this.titolo);
      formData.append("descrizione", this.descrizione);

      let jwt = localStorage.getItem("newsfeed_auth_token");
      // Ask to backend if current found jwt is valid, if it is then use it else ask for a new jwt
      // Prepare the message
      const headers = {
        Authorization: "Bearer " + jwt,
        "Content-Type": "multipart/form-data",
      };

      this.$http
        .post(process.env.VUE_APP_NEWSFEED_API, formData, {
          headers: headers,
        })
        .then((res) => {
          this.errorText =
            "La comunicazione è stata inviata correttamente a: " +
            res.data.recipients.replace(",", ", ") +
            ". Riceverai una copia via email.";
          this.snackbarColor = "success";
          this.timeout = 5000;
          this.permSnackbar = true;
          this.files = [];
          this.titolo = "";
          this.tipologia = "";
          this.descrizione = "";
          this.currentFiles = [];
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.errorText =
            "Errore nell'invio della comunicazione: " + err.response.data.error;
          this.snackbarColor = "error";
          this.timeout = -1;
          this.permSnackbar = true;
          // console.log(err.response);
        });
    },
    remove(index) {
      this.files.splice(index, 1);
    },
    inputChanged() {
      this.files = this.files.concat(this.currentFiles);
      this.currentFiles = [];
    },
    logout: function () {
      localStorage.removeItem("newsfeed_auth_token");
      window.location.href = process.env.VUE_APP_CAS_URL_LOGOUT;
    },
    isJwtValid: async function () {
      //console.log(jwt);
      // console.log("Validating jwt...");
      try {
        let status = await localstore.validateJwt();
        if (status === "valid") {
          try {
            this.jwtPayload = jwt_decode(
              localStorage.getItem("newsfeed_auth_token")
            );
            // console.log(this.jwtPayload)
          } catch (err) {
            // console.log(err);
          }
        }
      } catch (err) {
        this.redirectToCas();
      }
      /*
      localstore
        .validateJwt()
        .then((status) => {
          // console.log("validateJwt status: " + status)
          if (status === "valid") {
            try {
              this.jwtPayload = jwt_decode(
                localStorage.getItem("newsfeed_auth_token")
              );
              // console.log(this.jwtPayload)
            } catch (err) {
              // console.log(err);
            }
          }
        })
        .catch(() => {
          // console.log(err);
          // console.log("invalid jwt found! Redirecting to cas");
          this.redirectToCas();
        });
        */
    },

    redirectToCas: function () {
      window.location.href =
        process.env.VUE_APP_CAS_URL_FIRST_PART +
        encodeURIComponent(window.location.href);
      // console.log('redirecting to ' + process.env.VUE_APP_CAS_URL_FIRST_PART + encodeURIComponent(window.location.href));
      // https://sso.staging.unimi.it:6443/login?service=https%3A%2F%2Fpatrimoniotest.di.unimi.it%2F
    },

    validate: function (ticket, path) {
      //console.log(ticket);

      const headers = {};

      var url =
        process.env.VUE_APP_NEWSFEED_API +
        "/obtainJwt" +
        "?ticket=" +
        ticket +
        "&path=" +
        encodeURIComponent(path);

      // We are validating ticket to NEWSFEED that validate ticket to THOR, if valid we will obtain the newsfeed role of the user that has generated this ticket in a jwt.
      this.$http
        .get(url, { headers })
        .then((response) => {
          //console.log(response);

          if (response.status === 200) {
            // console.log('validated')
            // console.log(response.data)
            // jwt obtained we are now ready to set login true and to store the obtained jwt
            localStorage.setItem("newsfeed_auth_token", response.data);

            // If ticket present into the url redirect to home
            const myURL = URL.parse(window.location.href);
            const myQS = querystring.parse(myURL.query);
            if (myQS.ticket) {
              // this.$router.replace(myURL.pathname);
              // console.log(
              //   "ticket in url, redirecting to: " +
              //     myURL.href.replace(myURL.path, "")
              // );
              window.location.href = myURL.href.replace(myURL.path, "");
            }
          }
        })
        .catch((err) => {
          // console.log(err);
          if (err.response.status === 404) {
            // User not belonging to this dept.
            // TODO: redirect to info/error page
            // console.log(err.response.data);
            // this.$router.push("/usernotfound");
          } else {
            // console.log(err);
            // this.redirectToCas();
          }
        });
    },
  },
  computed: {
    invalidInput() {
      if (this.tipologia.trim() === "") {
        return true;
      }
      if (this.titolo.trim() === "") {
        return true;
      }
      if (this.descrizione.trim() === "") {
        return true;
      }
      if (this.totalsize > this.maxsize) {
        return true;
      }
      return false;
    },
  },
  created: async function () {
    document.title = "Comunicazione ufficio stampa di ateneo";
    this.loading = true;
    // for(let myvar of process.env) {
    //   console.log(myvar)
    // }
    // console.log("NODE_ENV: " + process.env.NODE_ENV);
    console.log("Newsfeed App Version: " + process.env.VUE_APP_VERSION);

    // Reset if error
    //this.serviceDown = false;

    // Try to get auth token, if empty it automatically try to load it from local storage
    // If empty check if we have stored it on local storage
    // console.log("looking for stored jwt");
    const jwt = localStorage.getItem("newsfeed_auth_token");
    // console.log('jwt: ' + jwt)
    const myURL = URL.parse(window.location.href);
    // console.log(myURL)
    const myQS = querystring.parse(myURL.query);

    // FTF we are verifing if a jwt is present, if it is then we check for validity
    // If not present then we need to talk with cas for authentication
    // This means that the first time we arrived here without a jwt or with an invalid jwt
    // We were redirected to cas and if login is fine he redirect us here again with a ticket
    // So we should also check if we land here with a ticket into the url
    // If it is present than it means that we were redirected here by cas after a successful login and we should now send the
    // ticket for validation to our backend.
    //console.log('jwt found in memory: ' + jwt);

    if (!(jwt === null)) {
      // console.log("jwt not null")
      await this.isJwtValid();
      this.loading = false;
      this.username = jwt_decode(jwt).username;
    } else {
      // JWT null or invalid, check if in the url there is the cas ticket if not redirect else call backend to validate
      // console.log(this.$route);

      if (myQS.ticket) {
        // probably we were redirected here by CAS, it's now time to call our backend, pass the ticket and wait for validation
        // console.log("Ok pass the ticket to the backend for validation");
        //console.log(this.$route.query.ticket); // outputs 'CAS ticket'

        // HERE WE CALL NEWSFEED BACKEND that call THOR FOR TICKET VALIDATION
        this.validate(
          myQS.ticket,
          myURL.protocol + "//" + myURL.host + myURL.pathname
        );
      } else {
        // console.log(
        //   "-> null or invalid or Expired token...go to cas to obtain a new ticket"
        // );
        this.redirectToCas();
      }
    }
  },
};
</script>
<style scoped>
h2 {
  color: #003366;
}
.field {
  border: 1px solid black;
}
</style>